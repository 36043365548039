<template>
    <!-- Hero section -->
    <main class="hero">
        <!-- Input form -->
        <div class="form" v-if="doLogin">
            <form @submit.prevent="login" method="post" id="main-form">
                <input
                    type="email"
                    name="original_url"
                    autocomplete="off"
                    placeholder="Enter your email"
                    v-model="email"
                    required
                    style="width: 400px; text-align: center"
                />
            </form>
            <button type="submit" form="main-form" class="submit-button">
                {{loginText}}
                <!--<img class="link-logo" src="static/assets/link-icon.svg" />-->
            </button>
        </div>

        <!-- Shortened link -->
        <div v-show="!doLogin">
            <div>
                <!-- <h2>Check your email for login link</h2>
                you can close this window -->
                <div  class="description">
                    <h2 class="tm-login-form">Email with login code sent!</h2>
                    <p>Go to your inbox to get the code and login!</p>
                </div>
                <div class="form" >

                <form @submit.prevent="verify" id="main-form">
                    <input
                        type="text"
                        autocomplete="off"
                        placeholder="Enter code from email"
                        v-model="otp"
                        required
                        style="width: 400px; text-align: center"
                        />
                </form>
                <button type="submit" form="main-form" class="submit-button">
                {{loginText}}
                <!--<img class="link-logo" src="static/assets/link-icon.svg" />-->
                </button>
                </div>
                <div v-show="otpError" class="description alert">
                    <h2 class="tm-login-form">Invalid or expired code!</h2>
                </div>
            </div>
        </div>

        <!-- About cards -->
        <section hidden class="cards-container"></section>
    </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: "HomeView",
    components: {
        // HelloWorld
    },
    computed: {
        emailUrl: function () {
            var splitEmail = this.email.split('@');
            return splitEmail.length > 1 ? `https://${splitEmail[1]}` : '';
        }
    },
    data: function () {
        return {
            email: "",
            otp:"",
            error:"",
            doLogin: true,
            loginText : 'Login',
            otpError: false
        };
    },
    methods: {       
        async login() {
            if (!this.email) {
                alert("Email required");
                return;
            }
            
            this.loginText = "Loging in ...";

            let resp = await fetch(`/api/login/${this.email}`);
            if (!resp.ok) {
                this.error="Unable to authenticate";
                console.dir(resp);
                return;
            }
            this.loginText = "Verify";
                this.doLogin = false;
                // alert('An email with a link to login has been sent to your email. Follow the link from the email to login!')
                console.log("closing the window...");

        },
        async verify() {
            if (!this.otp) {
                alert("Email code required");
                return;
            }
            this.otpError = false;
            this.loginText = "Loging in ...";
            let body =  {'email' : this.email, 'otp':this.otp};
            let resp = await fetch(`/api/verify/`,
            {
                method: "POST",
                headers:{
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(body)
            }
                                   );
            if (!resp.ok) {
                this.error="Unable to authenticate";
                console.dir(resp);
                this.otpError = true;
                this.loginText = "Verify";
                return;
            }

            this.doLogin = false;
            const token =await resp.text();
            sessionStorage.setItem("_token_", token);
            this.$store.state.isAuthenticated = true;

            console.log("token:");
            console.log(token)
            setTimeout(() => {
                this.$router.push("/history");
            },500);

        },
    },
};
</script>

<style scoped>
.alert{ color: red !important}
.description{
padding-bottom: 30px;
}
</style>
