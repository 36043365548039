<template>
    <div>
      <div v-show="apiKey">
      <h3 >Api Key: {{apiKey}}</h3>
      <pre class="pre-code">
        <code>
      curl -X POST -H "Content-Type: application/json" \
      -d '{"url":"[your_url]", "apiKey":"{{apiKey}}"}' \
      http://pingy.imhotepp.net/api/shorties    
      
        </code>
      </pre>
    </div>
      
      <h3 v-show="isLoading">Loading ...</h3>
      <h3 v-show="!isLoading && shorties?.length == 0">
        <br>
        No history available, yet!</h3>
      <div v-show="!isLoading">

    <table v-show="!isLoading && shorties?.length > 0" class="content-table">
      <thead>
        <tr>
          <th>Original URL</th>
          <th>Short URL</th>
          <th>Visits</th>
        </tr>
      </thead>
      <tbody>
        
        <tr  v-for="link in shorties" :key="link.id">
          <td>{{ link.url }}</td>
          <td>{{ link.shortUrl }}</td>
          <td>{{ link.clicks }}</td>
        </tr>
        
      </tbody>
    </table>
    </div>
  </div>  
</template>

<script>


export default {
  name:"HistoryVue",
  data: function(){
    return {
      shorties:[],
      apiKey:"",
      isLoading: true
    }
  },
  methods:{

    getShorts: async function(){
      console.log(`get shorts`)
      this.isLoading = true;
      const headers = {
        'Content-Type': 'application/json'
      }
      const token = sessionStorage.getItem("_token_");
      if (token) headers[  'Authorization'] = `Bearer ${token}`;
      try {
        let resp = await  fetch("/api/shorties",{
        method: 'GET',
        headers: headers
      });
      let json  = await resp.json();
      this.isLoading = false;
      this.shorties = json.shorties;
        this.apiKey = json.apiKey;
      console.dir(json)
        
      } catch (error) {
      this.isLoading = false;
      console.log(error.message)  
      }
      

    }
  },
  mounted() {
    this.getShorts();
  }
}
</script>
<style>
h3 {
  padding-top: 10px;
  text-align: center;
}
table{
  margin-top:20px !important;
}
.pre-code{
  text-align: center
}
</style>